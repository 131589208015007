// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-firm-js": () => import("./../../../src/pages/our-firm.js" /* webpackChunkName: "component---src-pages-our-firm-js" */),
  "component---src-pages-our-leadership-js": () => import("./../../../src/pages/our-leadership.js" /* webpackChunkName: "component---src-pages-our-leadership-js" */),
  "component---src-pages-service-offerings-js": () => import("./../../../src/pages/service-offerings.js" /* webpackChunkName: "component---src-pages-service-offerings-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

